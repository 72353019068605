import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const PageLangLink = ({ pathPrefix, defaultSlug, displayText }) => {
    let locationPath = '';
    if (typeof window !== 'undefined') {
        locationPath = window.location.pathname;
    }

    const linkPath = pathPrefix === '' ? `/${defaultSlug}` : `/${pathPrefix}/${defaultSlug}`;
    const className = linkPath.localeCompare(locationPath) === 0 ? 'has-text-white' : 'has-text-grey-light';

    return <Link to={linkPath} className={className} >{displayText}</Link> ;
}

const PageLangLinkList = ({ defaultSlug, availableLocales }) => {
    return (
        <ul className="columns is-mobile is-1">
            {availableLocales && availableLocales.map((locale, i) => (
                <li className="column" key={i}>
                    <PageLangLink
                        pathPrefix={locale.pathPrefix} 
                        defaultSlug={defaultSlug} 
                        displayText={locale.displayText}
                    />
                </li>
            ))}
        </ul>
    );
}

PageLangLinkList.propTypes = {
    defaultSlug: PropTypes.string.isRequired,
    availableLocales: PropTypes.arrayOf(
        PropTypes.shape({
            displayText: PropTypes.string.isRequired,
            pathPrefix: PropTypes.string,
        })
    ).isRequired,
}

export default PageLangLinkList;
