import React from "react";
import Tilt from 'react-tilt';

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PageLangLinkList from "../components/PageLangLinkList";
import { Button, ButtonSymbolHover } from "../components/Button";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";

import grey_outline_circle from "../assets/images/grey_outline_circle.svg";
import grey_outline_rectangle from "../assets/images/grey_outline_rectangle.svg";
import grey_outline_square from "../assets/images/grey_outline_square.svg";
import grey_outline_cylinder from "../assets/images/grey_outline_cylinder.svg";
import grey_outline_transcript from "../assets/images/grey_outline_transcript.svg";

export const ServicesHero = ({
  defaultSlug,
  availableLocales,
  heroImage,
  title,
  ingress,
  serviceCards,
}) => (
  <section style={{ position: "relative", minHeight: "100vh" }} className="section is-medium">
    
    {availableLocales && (
      <div className="container services--page-lang-links-container">
        <PageLangLinkList
          defaultSlug={defaultSlug}
          availableLocales={availableLocales} />
      </div>
    )}

    <GatsbyImage
      image={getImage(heroImage)}
      style={{ width: "100%", position: "absolute", bottom: "0", left: "0", zIndex: "-1", height: "100%" }}
      alt="" />
    <div className="container has-text-centered">
      <h1 className="font-bold size-100 is-transparent text-stroke-white">
        {title}
      </h1>
      <div className="columns is-mobile is-vcentered is-multiline">
        <div className="column has-text-centered">
          <div className="padding-y-20 is-white size-30" dangerouslySetInnerHTML={{ __html: ingress.childMarkdownRemark.html }} />
        </div>
      </div>
    </div>
    <div className="container content">
      <div className="columns is-mobile is-multiline">
        {serviceCards.map((card, i) => (
          <div className="column is-6-desktop is-12-mobile" key={i}>
            <TierCard
              serviceAttributes={card.serviceAttributes}
              serviceCtaText={card.serviceCtaText}
              serviceCtaUrl={card.serviceCtaUrl}
              serviceDescription={card.serviceDescription}
              serviceName={card.serviceName}
            />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export const ServicesContent = ({ content, definedTermsPopUpItems }) => (
  <section className="section">
    <div className="container content services-content">
        <ContentHTMLWithHoverPopUps
            contentHTML={content.childMarkdownRemark.html} 
            popUpItems={definedTermsPopUpItems}
        />
    </div>
  </section>
);

export const ServicesNumbers = ({ numbers, color }) => {
  const numbersIcons = [
    grey_outline_square,
    grey_outline_circle,
    grey_outline_cylinder,
    grey_outline_rectangle,
    grey_outline_transcript
  ];

  return (
    <section className="section">
      <div className="container content">
        <div className="columns">
          {numbers.map((number, i) => (
            <div className="column" key={i}>
              <div style={{ position: "relative", textAlign: "center" }}>
                <img src={numbersIcons[i]} alt="" style={{ width: "100%" }} />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                  }}
                  className="content"
                >
                  <h2 className={`title is-${color}`}>{number}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
};

export const ServicesAskUsAnything = () => (
  <section className="section">
    <div className="container content">
      <ButtonSymbolHover
        color="purple"
        text="contact us"
        type="outlined"
        to="/ask-us-anything"
      />
    </div>
  </section>
);

const TierCard = ({ serviceCtaText,
  serviceAttributes,
  serviceCtaUrl,
  serviceDescription,
  serviceName }) => {
  return (
    <Tilt className="Tilt" options={{ max: 5, scale: 1.015 }}>
      <div className="Tilt-inner">
        <div className="card is-white-bg services-card">
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <h2 className="size-28 is-purple">{serviceName}</h2>
              </div>
            </div>
            <div className="content">
              <p className="size-18 line-height-26">{serviceDescription}</p>
              {serviceAttributes.map((x, i) => (
                <div className="flex flex-vcenter" key={i} style={{ marginBottom: "5px" }}>
                  <div className="is-purple-arrow">
                    <div className="line"></div>
                    <div className="point"></div>
                  </div>
                  <span className="font-medium size-22 line-height-32" style={{ paddingLeft: "20px" }}>{x}</span>
                </div>
              ))}
            </div>
          </div>
          <Button type="outlined" customStyles={{ height: "50px" }} textClass="size-20" color="purple" text={serviceCtaText} to={serviceCtaUrl} />
        </div>
      </div>
    </Tilt>
  )
}