import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout/Layout";
import { ServicesHero, ServicesContent, ServicesNumbers, ServicesAskUsAnything } from "../components/ServicesPageComponents";
import { getDefinedTermPopUpItem } from "../helpers";

export default ({ pageContext, data, location }) => {
    const {
        ingress,
        color,
        heroImage,
        createdAt,
        updatedAt,
        title,
        numbers,
        introContent,
        content,
        serviceCards
    } = data.contentfulServices;

    const seoData = {
        updatedAt: updatedAt,
        createdAt: createdAt,
        title: title,
        author: "Frends team",
    }

    const { default_slug, available_locales } = pageContext;

    const definedTermsPopUpItems = data.allContentfulDefinedTerm.nodes.map(getDefinedTermPopUpItem);

    return (
        <Layout
            title={title}
            transparentNavigation={true}
            seoData={seoData}
            location={location}
        >
            <main id="services-content" style={{ marginTop: "-80px" }}>
                <ServicesHero
                    defaultSlug={default_slug}
                    availableLocales={available_locales}
                    heroImage={heroImage}
                    title={title}
                    ingress={ingress}
                    serviceCards={serviceCards}
                />
                <ServicesContent 
                    content={introContent}
                    definedTermsPopUpItems={definedTermsPopUpItems}
                />
                <ServicesNumbers
                    numbers={numbers}
                    color={color}
                />
                <ServicesContent 
                    content={content}
                    definedTermsPopUpItems={definedTermsPopUpItems}
                />
                <ServicesAskUsAnything />
            </main>
        </Layout>
    );
}

export const query = graphql`
query ($id: String!, $contentful_content_vocabulary_item_slugs: [String]) {
    contentfulServices(id: {eq: $id}) {
        updatedAt
        createdAt
        introContent {
            childMarkdownRemark {
            html
            }
        }
        content {
            childMarkdownRemark {
                html
            }
        }
        ingress {
            childMarkdownRemark {
            html
            }
        }
        heroImage {
            gatsbyImageData(width: 1920)
        }
        color
        numbers
        title
        serviceCards {
            serviceCtaText
            serviceAttributes
            serviceCtaUrl
            serviceDescription
            serviceName
        }
    }
    allContentfulDefinedTerm(
        filter: {slug: {in: $contentful_content_vocabulary_item_slugs}, node_locale: {eq: "en-US"}}
        ) {
        nodes {
            slug
            title
            description {
                childMarkdownRemark {
                    html
                    excerpt
                }
            }
        }
    }
}  
`
